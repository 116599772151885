import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Navbar from "../navbar/Navbar";
import jwt_decode from "jwt-decode";
import { Switch } from "react-router-dom";
import ProtectedRoute from "../router/ProtectedRoute";
import DashboardContainer from '../../containers/DashboardContainer';
import ProductDivisionContainer from '../../containers/ProductDivisionContainer';
import ProductDivisionDetailedContainer from '../../containers/detailed-view/ProductDivisionContainer';
import SBSDetailedContainer from '../../containers/detailed-view/SBSContainer'
import SubRegionDetailedContainer from '../../containers/detailed-view/SubRegionContainer'
import CustomerContainer from '../../containers/detailed-view/Customer'
import GroupContainer from '../../containers/detailed-view/Group'
import ScoreContainer from '../../containers/ScoreContainer'
import AmbiguityContainer from '../../containers/AmbiguityContainer'
import PrimaryView from '../../containers/kpi/PrimaryView'
import nbaPrimaryView from '../../containers/nba/PrimaryView'
import ChildView from '../../containers/kpi/ChildView'
import nbaChildView from '../../containers/nba/ChildView'
import History from '../../containers/kpi/History'
import nbaHistory from '../../containers/nba/History'
import * as utils from "../../utils/utils";
import SASContainer from '../../containers/cas/ChildView';
import KPIperformance from '../../containers/config/KPIperformance'
import EditKPIperformance from '../../containers/config/EditKPIperformance'
import Search from "../../containers/search/Search";
import ConfigHome from '../../pages/config/home/Home'
import ParentWeightage from '../../containers/config/ParentWeightage'
import BannerInformation from '../../containers/config/BannerInformation';
import ChildWeightage from '../../containers/config/ChildWeightage'
import ChildWeightageDivision from '../../containers/config/ChildWeightageDivision'

const Home = (props) => {
  let [decoded, setdecoded] = useState(null);

  const saveTokenInLocal = () => {
    const access_token = new URL(window.location.href).hash
      .split("&")
      .filter(function (el) {
        if (el.match("access_token") !== null) return true;
      })[0]
      .split("=")[1];

    const id_token = new URL(window.location.href).hash
      .split("&")
      .filter(function (el) {
        if (el.match("id_token") !== null) return true;
      })[0]
      .split("=")[1];

    const token_type = new URL(window.location.href).hash
      .split("&")
      .filter(function (el) {
        if (el.match("token_type") !== null) return true;
      })[0]
      .split("=")[1];

    setdecoded(
      jwt_decode(
        new URL(window.location.href).hash
          .split("&")
          .filter(function (el) {
            if (el.match("id_token") !== null) return true;
          })[0]
          .split("=")[1]
      )
    );

    const __tf_token = { access_token, id_token, token_type };
    localStorage.setItem("__tf_token", JSON.stringify(__tf_token));
    window.history.replaceState({}, "", "/dashboard");
  };

  useEffect(() => {
    if (window.location.href.indexOf("localhost") > -1) {
      if (new URL(window.location.href).hash.length) {
        saveTokenInLocal();
      } else {
        const _token = JSON.parse(localStorage.getItem("__tf_token"));
        setdecoded(jwt_decode(_token.id_token));
      }
    } else if (new URL(window.location.href).hash.length) {
      saveTokenInLocal();
    } else if (
      localStorage.getItem("__tf_token") &&
      localStorage.getItem("__tf_token").length
    ) {
      const _token = JSON.parse(localStorage.getItem("__tf_token"));
      setdecoded(jwt_decode(_token.id_token));
    }
    console.log('App version: 0.10- Feedback form');
  }, []);

  return (
    <React.Fragment>
      {decoded && (
        <div className="App">
          <Navbar {...props} />
          <div className="innerContainer">
            <Header decoded={decoded} {...props} />
            <Switch>
              <ProtectedRoute
                exact
                path={`${props.match.path}`}
                Component={DashboardContainer}
                isAuthenticated={true}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/ambiguity`}
                Component={AmbiguityContainer}
                isAuthenticated={true}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/config`}
                Component={ConfigHome}
                isAuthenticated={true}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/config/kpi/parent`}
                Component={ParentWeightage}
                isAuthenticated={true}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/config/banner`}
                Component={BannerInformation}
                isAuthenticated={true}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/config/kpi/child`}
                Component={ChildWeightage}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.parentKPI)}
                redirectTo={`${props.match.path}/config/kpi/parent`}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/config/kpi/child/division`}
                Component={ChildWeightageDivision}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.parentKPI)}
                redirectTo={`${props.match.path}/config/kpi/parent`}
                {...props}
              />
              <ProtectedRoute
                path={`${props.match.path}/search`}
                Component={Search}
                isAuthenticated={true}
                {...props}
              />
              <ProtectedRoute
                path={`${props.match.path}/score`}
                Component={ScoreContainer}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.cxScoreDetails)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/productdivision`}
                Component={ProductDivisionContainer}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.selectedProductDivision)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/groupdetail`}
                Component={GroupContainer}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.group)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/customerdetail`}
                Component={CustomerContainer}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.customer)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/productdivisiondetail`}
                Component={ProductDivisionDetailedContainer}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.selectedProductDivision)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/sbsdetail`}
                Component={SBSDetailedContainer}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.selectedProductDivision)}
                {...props}
              />
             {<ProtectedRoute
                exact
                path={`${props.match.path}/subregiondetail`}
                Component={SubRegionDetailedContainer}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.selectedSubRegion)}
                {...props}
              /> }
              <ProtectedRoute
                exact
                path={`${props.match.path}/primaryview`}
                Component={PrimaryView}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.selectedKPI)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/childview`}
                Component={ChildView}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.selectedKPI)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/history`}
                Component={History}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.selectedKPI)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/nba/primaryview`}
                Component={nbaPrimaryView}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.nba)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/nba/childview`}
                Component={nbaChildView}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.nba)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/nba/history`}
                Component={nbaHistory}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.nba)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/cas-childview`}
                Component={SASContainer}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.cxScoreDetails)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/config/kpi/performance`}
                Component={KPIperformance}
                isAuthenticated={true}
                // notStable={utils.isEmpty(props.group)}
                {...props}
              />
              <ProtectedRoute
                exact
                path={`${props.match.path}/config/kpi/updateperformance`}
                Component={EditKPIperformance}
                isAuthenticated={true}
                notStable={utils.isEmpty(props.config)}
                {...props}
              />
            </Switch>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Home;
